import portfolio from '../images/portfolio.jpg'
import offer2 from '../images/offer2.jpg'
import portfolioSample from '../images/portfolioSample.jpg'


const portfolioImgData = [
    {
        id: 1,
        image: portfolio
    },
    {
        id: 2,
        image: offer2
    },
    {
        id: 3,
        image: portfolioSample
    },
    {
        id: 4,
        image: portfolio
    },
    {
        id: 5,
        image: offer2
    },
    {
        id: 6,
        image: portfolioSample
    },
    {
        id: 7,
        image: portfolio
    },
    {
        id: 8,
        image: offer2
    },
    {
        id: 9,
        image: portfolioSample
    },
    {
        id: 10,
        image: portfolio
    },
    {
        id: 11,
        image: offer2
    },
    {
        id: 12,
        image: portfolioSample
    },


    




]

export default portfolioImgData;