
import flowerReview from '../images/flowerReview.png'

const reviewCardsData = [
    {
        id: 1,
        author: "cauiseyngud sjfjdgfjdgf dfgvgdf",
        review: "lorem i psumdfhdjgf djgddjfgdjdfg",
        image: flowerReview
    },
    {
        id: 2,
        author: "cauis eyngudsjfj dgfjdgf  dfgvgdf",
        review: "lorem ipsumdfhdjgfdjgddjfgdjdfg",
        image: flowerReview
    },
    {
        id: 3,
        author: "cauis eynguds jfjdgfjd gfdfgvgdf",
        review: "lorem ipsumdfhdjgfdjgddjfgdjdfg",
        image: flowerReview
    },
    {
        id: 4,
        author: "caui seyngudsjfjdgf jdgfdfgvgdf",
        review: "lorem ipsumdfhdjgf djgddjfgdjdfg",
        image: flowerReview
    },
    {
        id: 5,
        author: "caui seyngudsjfjdgfjd gfdfgvgdf",
        review: "lorem ipsumdfhdjgfdjgd djfgdjdfg",
        image: flowerReview
    },
    {
        id: 6,
        author: "ca uiseyngudsjfjdgfj dgfdfgvgdf",
        review: "lorem ipsumdfhdjgfdj gddjfgdjdfg",
        image: flowerReview
    },
]

export default reviewCardsData;