import * as React from 'react';


const Logo =() =>{
  
    return(
        <div className="logo div2">
            <div className="pink-dot"></div>
            <div style={{fontSize:"2rem"}}>Serenity Flowers</div>
            
        </div>
    )
        
      
}

export default Logo;